<script>
import tagForm from "@/views/menu/taxonomy/tag/tagForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    tagService: "tagService"
  }
})
export default class TagDetail extends mixins(tagForm) {
  formId = "detail-tag-form";

get decoratedElements() {
    return this.allReadonlyFields;
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle () {
    return this.translations.pageTitles.tags_detail;
  }

  async afterCreate () {
    this.model = await this.tagService.read(this.$route.params.id);
    this.isReady = true;
  }
}
</script>
